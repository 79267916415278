<template>
  <AuctionListItemInfoElement
    v-if="department && department.name !== 'online'"
    icon="mdi:location"
  >
    <CommonLink
      v-if="department && department.name !== 'online'"
      underline
      class="flex items-center gap-1"
      :href="`/${locale}/${convertTextToSlug(department.name.toLowerCase())}`"
      @click.stop
    >
      <span class="text-sm"> {{ department.name }} </span>
    </CommonLink>
  </AuctionListItemInfoElement>
</template>
<script setup lang="ts">
import CommonLink from '@autobid/ui/components/common/Link.vue'
import type { DisplayingAuction } from '@autobid/ui/types/components/AuctionsData'
import { convertTextToSlug } from '@autobid/ui/utils/slugUtils'
import AuctionListItemInfoElement from './AuctionListItemInfoElement.vue'

const { department } = inject<DisplayingAuction>('auction')

const { locale } = useI18n()
</script>
